<template>
  <div class="main">
    <div v-if="loading" class="loader">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>
    <div v-else>
      <!-- <div class="aboutHeader">
        <h3>{{ product && product.name }}</h3>
        <div class="aboutHeader-link">
          <p>
            <router-link style="text-decoration: none" to="/">
              <v-icon style="margin-top: -4px"> mdi-home </v-icon>
            </router-link>
            <span
              style="text-decoration: none; color: black; cursor: pointer"
              @click="viewCategory(product.category.id)"
            >
              {{ product && product.category.name }}
            </span>
            <span to="/bikeStand" style="text-decoration: none; color: black">
              {{ product && product.name }}
            </span>
          </p>
        </div>
      </div> -->

      <div class="productMain">
        <div class="productImage">
          <carousel :per-page="1">
            <slide v-for="image in product.images" :key="image.id">
              <img
                :src="image.img"
                alt="product"
                style="width: 100%; height: 100%; object-fit: contain"
              />
            </slide>
          </carousel>
        </div>

        <div class="productContent">
          <h2 class="pTitle">
            {{ product && product.name }}
          </h2>
          <h2 style="color: var(--color-primary)">
            £{{ product && product.price && Number(product.price).toFixed(2) }}
          </h2>
          <p v-if="this.inStock" style="color: var(--color-primary)">
            {{ this.inStock }} in stock
          </p>
          <p
            v-else
            style="
              color: red;
              font-size: 20px;
              font-weight: bold;
              margin-top: 20px;
              text-transform: uppercase;
            "
          >
            out of stock
          </p>
          <div v-if="this.inStock" class="counter">
            <div class="nCounter" @click="nCount">
              <v-icon color="white"> mdi-minus </v-icon>
            </div>
            <div class="count">{{ count }}</div>
            <div class="pCounter" @click="pCount">
              <v-icon color="white"> mdi-plus </v-icon>
            </div>
          </div>
          <button v-if="this.inStock" @click="addToCart">Add to cart</button>
          <div
            v-html="product && product.short_description"
            style="margin: 20px 0"
          ></div>
        </div>
      </div>

      <div class="detailsHeader" v-if="product && product.long_description">
        <p>DESCRIPTION</p>
      </div>

      <div
        class="detailsContent"
        v-html="product && product.long_description"
      ></div>

      <div class="headerTitle">
        <h2>
          Related
          <span style="color: var(--color-primary); font-weight: 700"
            >Products</span
          >
        </h2>

        <div class="hr">
          <hr style="border: 2px solid var(--color-primary); width: 3px" />
          <hr style="border: 2px solid var(--color-primary); width: 3px" />
          <hr style="border: 2px solid var(--color-primary); width: 3px" />
          <hr style="border: 2px solid var(--color-primary); width: 100px" />
        </div>
      </div>

      <div class="products">
        <div v-if="relatedProductsLoading" class="loader">
          <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
          </div>
        </div>

        <div v-else class="product-cards">
          <div
            v-for="product in relatedProducts"
            :key="product.id"
            class="product-card"
            @click="viewProduct(product.id)"
          >
            <span
              class="stock"
              v-if="product.in_stock > 0"
              style="background-color: green"
            >
              In Stock
            </span>
            <span v-else class="stock" style="background-color: red">
              Out of Stock
            </span>
            <img :src="product.images[0].img" alt="product" />
            <h3>{{ product.name }}</h3>
            <p>
              <span
                v-if="product.discount_price && product.discount_price > 0"
                style="
                  text-decoration: line-through;
                  color: red;
                  margin-right: 5px;
                "
                >£{{ product.discount_price }}</span
              >
              £{{ product.price }}
            </p>

            <button>View Product</button>
          </div>
        </div>
      </div>
    </div>

    <ErrorDialog v-model="error" />
  </div>
</template>

<script>
import ErrorDialog from "../components/ErrorDialog.vue";
import { supabase } from "@/utils/supabase";

export default {
  name: "ProductView",
  components: { ErrorDialog },
  async mounted() {
    await this.getProduct();
    await this.getRelatedProducts();
    this.carts = JSON.parse(localStorage.getItem("cart"));
    if (!this.carts) this.carts = {};
  },
  watch: {
    async $route() {
      await this.getProduct();
      await this.getRelatedProducts();
    },
  },
  data() {
    return {
      count: 1,
      carts: {},
      error: false,
      inStock: 0,

      loading: false,
      product: {},
      relatedProductsLoading: false,
      relatedProducts: [],
    };
  },
  methods: {
    viewCategory(id) {
      this.$router.push("/categories?id=" + id);
    },

    viewProduct(id) {
      if (!id) return;
      this.$router.push("/products?id=" + id);
    },

    async getProduct() {
      this.loading = true;
      const { data, error } = await supabase
        .from("products")
        .select("*, category(*)")
        .eq("id", this.$route.query.id)
        .limit(10);
      if (error) {
        console.log(error);
      } else {
        Promise.all(
          data[0].images.map(async (image) => {
            const { data, error } = await supabase.storage
              .from("products")
              .getPublicUrl(image.img);
            if (error) {
              console.log(error);
            } else {
              image.img = data.publicUrl;
            }
          })
        );

        this.product = data[0];
        this.inStock = data[0].in_stock;

        if (this.product.short_description) {
          this.product.short_description =
            this.product.short_description.replace(
              /<p/g,
              '<p style="margin-bottom: 0px;"'
            );
        }

        if (this.product.long_description) {
          this.product.long_description = this.product.long_description.replace(
            /<p/g,
            '<p style="margin-bottom: 0px;"'
          );
        }

        this.loading = false;
      }
    },

    async getRelatedProducts() {
      this.relatedProductsLoading = true;
      const { data, error } = await supabase
        .from("products")
        .select("*, category(*)")
        .eq("category", this.product.category.id)
        .neq("id", this.product.id)
        .limit(10);
      if (error) {
        console.log(error);
      } else {
        Promise.all(
          data.map(async (product) => {
            const { data, error } = await supabase.storage
              .from("products")
              .getPublicUrl(product.images[0].img);
            if (error) {
              console.log(error);
            } else {
              product.images[0].img = data.publicUrl;
            }
          })
        );

        this.relatedProducts = data;
        this.relatedProductsLoading = false;
      }
    },

    pCount() {
      if (this.inStock > 1) {
        this.count += 1;
        this.inStock -= 1;
      }
    },
    nCount() {
      if (this.count > 1) {
        this.count -= 1;
        this.inStock += 1;
      }
    },
    addToCart() {
      if (this.inStock > 0) {
        const product = {
          id: this.product.id,
          productTitle: this.product.name,
          price: this.product.price,
          quantity: this.count,
          subTotal: this.count * this.product.price,
        };

        this.carts[product.id] = product;
        localStorage.setItem("cart", JSON.stringify(this.carts));
        this.error = true;
        this.count = 1;
      }
    },
  },
};
</script>

<style scoped>
.main {
  margin: 20px;
}

.headerTitle {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headerTitle h2 {
  font-weight: 300;
  font-size: 24px;
}

.hr {
  display: flex;
  margin-top: 10px;
}

.hr hr {
  border-radius: 5px;
  margin: 0px 5px;
}

/* loader */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--color-primary);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.productMain {
  display: flex;
  gap: 40px;
  margin-top: 40px;
}

.productImage {
  width: 50%;
}

.productContent {
  width: 50%;
}

.productContent h2 {
  padding: 6px 0px;
}

.productContent button {
  margin-top: 30px;
  background-color: var(--color-primary);
  color: white;
  padding: 10px 30px;
  border-radius: 50px;
  text-transform: uppercase;
  transition: 0.3s;
}

.productContent button:hover {
  transform: scale(1.1);
}

.counter {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  user-select: none;
}

.nCounter,
.pCounter {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);
  padding: 3px;
  border-radius: 50%;
  cursor: pointer;
}

.nCounter:hover,
.pCounter:hover {
  background-color: #2e6f23;
}

.count {
  padding: 4px 16px;
  border: 1px solid var(--color-primary);
  border-radius: 5px;
}

.detailsHeader {
  margin-top: 40px;
}

.detailsHeader p {
  font-size: 20px;
  font-weight: bold;
}

.detailsContent {
  margin-top: 20px;
}

/* related products */
.products {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 40px;
}

.product-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.product-card {
  position: relative;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  cursor: pointer;
  overflow: hidden;
}

.stock {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  z-index: 99;
}

.product-card img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 5px;
  transition: 0.3s;
}

.product-card img:hover {
  transform: scale(1.1);
}

.product-card h3 {
  font-size: 16px;
  overflow: hidden;
}

.product-card p {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.product-card p span {
  font-size: 16px;
  font-weight: normal;
}

.product-card button {
  width: 90%;
  padding: 8px 0px;
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: white;
  cursor: pointer;
  transition: 0.3s;
  font-size: 15px;
}

.product-card button:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .productMain {
    flex-direction: column;
  }

  .productImage {
    width: 100%;
  }

  .productContent {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .product-cards {
    gap: 10px;
  }

  .product-card {
    max-width: 155px;
  }

  .product-card img {
    height: 150px;
  }

  .product-card h3 {
    font-size: 11px;
  }

  .product-card p {
    font-size: 16px;
  }

  .product-card button {
    padding: 5px 0px;
    font-size: 11px;
  }

  .stock {
    padding: 3px 5px;
    font-size: 10px;
  }
}
</style>
