<template>
  <div>
    <div class="yourProduct">
      <div class="productDetails">
        <v-card-title>YOUR ORDER</v-card-title>
        <v-data-table
          :headers="headers"
          :items="cartItems"
          hide-default-footer
          style="box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)"
        >
          <template v-slot:item.subTotal="{ item }">
            <h4>{{ "$" + item.subTotal }}</h4>
          </template>
        </v-data-table>
        <table id="table" class="table">
          <tbody>
            <tr>
              <th>Subtotal</th>
              <td>{{ "£" + totalprice }}</td>
            </tr>
            <tr>
              <th>Shipping</th>
              <td>Free Shipping</td>
            </tr>
            <tr>
              <th>Coupon</th>
              <td v-show="clickCpn">
                <v-btn @click="coppen" dark color="primary" small elevation="0"
                  >Apply coupon</v-btn
                >
              </td>
              <td v-if="showCpn" class="d-flex flex-column align-end">
                <v-form
                  class="form"
                  @submit.prevent="coupen"
                  ref="form"
                  lazy-validation
                >
                  <v-text-field
                    class="input"
                    type="text"
                    v-model="copen"
                    :rules="[required(), cpn()]"
                    outlined
                    dense
                    small
                    style="margin-bottom: -18px"
                  >
                  </v-text-field>
                  <v-btn
                    @click="
                      showCpn = false;
                      clickCpn = true;
                    "
                    dark
                    color="red"
                    small
                    outlined
                    class="mr-2 mt-3 mb-n3"
                    >Close</v-btn
                  >
                  <v-btn
                    type="submit"
                    dark
                    color="primary"
                    small
                    outlined
                    class="mt-3 mb-n3"
                    >Apply Coupon</v-btn
                  >
                </v-form>
              </td>
              <td v-show="cpnText">
                <h4>Whey To Go 20% Discount</h4>
              </td>
            </tr>
            <tr>
              <th>Total</th>
              <td v-if="this.per == true">
                {{ "£" + (totalprice * 0.9).toFixed(2) }}
              </td>
              <td v-else>{{ "£" + totalprice }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <card />

      <!-- <div class="paypal">
        <div class="paypalContent"></div>
        <div ref="paypal"></div>
      </div> -->
    </div>

    <OrderSuccessfull
      v-model="orderSuccesfull"
      :message="this.orderID || 'Null'"
    />
  </div>
</template>

<script>
import { supabase } from "@/utils/supabase";

import { required, cpn } from "@/utils/validators";
import OrderSuccessfull from "@/components/OrderSuccessfull.vue";
import card from "@/views/card.vue";

export default {
  data() {
    return {
      orderID: "",
      orderSuccesfull: false,
      clickCpn: true,
      showCpn: false,
      cpnText: false,
      per: false,
      copen: null,
      product: {
        price: null,
      },
      carts: [],
      headers: [
        { text: "Product", value: "productTitle" },
        { text: "Quantity", value: "quantity" },
        { text: "Price", value: "price" },
        { text: "SubTotal", value: "subTotal" },
      ],
      Order: null,
      payData: null,
      userInfo: {},

      // card details
      valueFields: {
        cardName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
      },
    };
  },
  components: {
    OrderSuccessfull,
    card,
  },
  computed: {
    cartItems2() {
      return Object.values(this.carts);
    },

    cartItems() {
      let cartItem = [];
      if (this.carts != null) {
        cartItem = Object.values(this.carts);
      }
      return cartItem;
      // return Object.values(this.carts);
    },
    totalprice() {
      return this.cartItems
        .reduce((prev, value) => prev + value["subTotal"], 0)
        .toFixed(2);
    },
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("paymentInfo"));
    this.loadCarts();
    // const script = document.createElement("script");
    // script.src =
    //   "https://www.paypal.com/sdk/js?currency=CAD&client-id=AWDQG30UQXZ7r5xWRSefY-QZ1pK7hnLYdDshz5AnC12raZGIU_xedU-KQrzPnTGl-MswUsuDHTzblS_t";
    // script.addEventListener("load", this.setLoaded);
    // document.body.appendChild(script);
    // window.setInterval(() => {
    //   this.refresh();
    // }, 300);
  },
  methods: {
    required,
    cpn,
    nullCart() {
      localStorage.setItem("cart", "null");
    },
    loadCarts() {
      this.carts = JSON.parse(localStorage.getItem("cart"));
    },
    refresh() {
      if (this.per == true) {
        this.product.price = (this.totalprice * 0.8).toFixed(2);
      } else {
        this.product.price = this.totalprice;
      }
    },
    coupen() {
      if (this.$refs.form.validate()) {
        this.per = true;
        this.showCpn = false;
        this.cpnText = true;
      }
    },
    coppen() {
      (this.showCpn = true), (this.clickCpn = false);
    },
    setLoaded: function () {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: this.product.price,
                    currency_code: "CAD",
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            this.Order = order;
            this.orderID = order.id;

            const { error } = await supabase.from("payments").insert([
              {
                payment: this.Order,
              },
            ]);

            if (error) {
              console.log(error);
            }

            this.nullCart();
            this.orderSuccesfull = true;
          },
          onError: (err) => {
            console.log(err);
          },
        })
        .render(this.$refs.paypal);
    },

    async payNow() {
      if (this.$refs.cardForm.validate()) {
        this.payData = {
          cardName: this.valueFields.cardName,
          cardNumber: this.valueFields.cardNumber,
          cardMonth: this.valueFields.cardMonth,
          cardYear: this.valueFields.cardYear,
          cardCvv: this.valueFields.cardCvv,
        };

        const { error } = await supabase.from("payments").insert([
          {
            payment: this.payData,
          },
        ]);

        if (error) {
          console.log(error);
        }

        this.nullCart();
        this.orderSuccesfull = true;
      }
    },
  },
};
</script>

<style scoped>
.form {
  width: 30%;
}

@media (max-width: 600px) {
  .form {
    width: 100%;
  }
}
</style>

<style>
.yourProduct {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.productDetails {
  width: 95%;
}

.table {
  width: 100%;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.productDetails table th {
  text-align: left;
  padding: 8px;
}

.productDetails table td {
  text-align: right;
  padding: 8px;
}

.paypal {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .paypal {
    width: 100%;
  }
}
</style>
